<script>
  export let latitude;
  export let longitude;
  export let description;
  export let radiusInKm = "3";

  const  R = 6371;  // earth radius in km
  const radius = parseInt(radiusInKm); //distance in km from the point
  const lat = parseFloat(latitude);
  const long = parseFloat(longitude);

  Math.toDegrees = function(rad) {
        return rad * (180 / Math.PI);
    }
  Math.toRadians = function(deg) {
      return deg * (Math.PI / 180);
  }

  const square = {};
  square.x1 = long - Math.toDegrees(radius/R/Math.cos(Math.toRadians(lat)));
  square.x2 = long + Math.toDegrees(radius/R/Math.cos(Math.toRadians(lat)));
  square.y1 = lat + Math.toDegrees(radius/R);
  square.y2 = lat - Math.toDegrees(radius/R);

</script>

<style> 
    #map-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #title-container {
        height: 5rem;
        text-align: center;
    }

    iframe {
        height: 300px;
    }

</style>


<div id="map-container">
  <div id="title-container">
    <h4>{description}</h4>
  </div>

  <iframe
    title={description}
    frameborder="0"
    scrolling="no"
    marginheight="0"
    marginwidth="0"
    src="https://www.openstreetmap.org/export/embed.html?bbox={square.x1}%2C{square.y1}%2C{square.x2}%2C{square.y2}&amp;layer=mapnik&amp;marker={latitude}%2C{longitude}"
    style="border: 1px solid black"
  />
  <br /><small
    ><a
      href="https://www.openstreetmap.org/?mlat={latitude}&amp;mlon={longitude}#map=19/{latitude}/{longitude}"
      target="_blank"
      >Ouvrir dans OpenStreetMap (et voir les parkings)</a
      >
      <br />
      <a
      href="https://www.google.fr/maps/place/{latitude}+{longitude}"
      target="_blank"
      >Ouvrir dans Google Maps</a
    ></small>
    <br />
</div>

