<script>
  import Map from '../components/Map.svelte';
  import Modal, { getModal } from '../components/Modal.svelte';
  import Box from '../components/Box.svelte';

  export let location;

  let detailsImgUrl = '/assets/parking_eglise.png';
  let DateCeremonieReligieuse = new Date('2021-09-18T15:30:00');
  let DatemariageCivil = new Date('2021-09-17T17:00:00');

  function FormatDateInWordsWithHour(date) {
    return date.toLocaleString('default', {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
    });
  }
</script>

<a
  href="https://www.service-public.fr/particuliers/actualites/A14896"
  target="_blank"
>
  <div class="right-corner">
    <span>Passe sanitaire obligatoire</span>
    <img
      id="tousAntiCovid"
      src="assets/logo-TousAntiCovid.png"
      alt="TousAntiCovid"
    />
  </div>
</a>

<div id="info-container" class="flex-col">
  <h1>Informations pratiques</h1>

  <div class="flex-row">
    <div class="flex-col">
      <Box>
        <h2>{FormatDateInWordsWithHour(DatemariageCivil)}</h2>
        <h3>Mariage civil</h3>
        <div>
          <small>à la Mairie de Chappes, </small>
          <small>suivi d'un moment convivial au Château Bois Rigaud.</small>
        </div>
      </Box>
    </div>

    <div class="flex-col">
      <Box>
        <h2>{FormatDateInWordsWithHour(DateCeremonieReligieuse)}</h2>
        <h3>Cérémonie religieuse</h3>
        <div>
          <small>à l'Eglise Notre-Dame de Saint-Saturnin.</small>
          <small>Vin d'honneur et repas au Château Bois Rigaud.</small>
        </div>
      </Box>
    </div>
  </div>

  <h2>Lieux accueillant l'événement</h2>
  <div class="flex-row">
    <div class="flex-col">
      <Map
        longitude="3.22034"
        latitude="45.86972"
        description="Mairie de Chappes (63720)"
      />
      <div>
        <button on:click={() => getModal('modal_parking_mairie').open()}>
          Voir les parkings
        </button>
      </div>
    </div>
    <div class="flex-col">
      <Map
        longitude="3.0934707820415497"
        latitude="45.660070126440665"
        description="Eglise Notre-Dame de Saint-Saturnin (63450)"
      />
      <div>
        <button on:click={() => getModal('modal_parking_eglise').open()}>
          Voir les parkings
        </button>
      </div>
    </div>
    <div class="flex-col">
      <Map
        longitude="3.34546"
        latitude="45.52472"
        description="Château Bois Rigaud (63490)"
        radiusInKm="20"
      />
    </div>
  </div>

  <h2>Liste des gîtes</h2>
  <div class="flex-row">
    <div class="flex-col">
      <Map
        longitude="3.3373"
        latitude="45.52779"
        description="Auberge de Margot (63490)"
        radiusInKm="1"
      />
    </div>
    <div class="flex-col">
      <Map
        longitude="3.33762"
        latitude="45.5266"
        description="Gîte De deux choses lune (63490)"
        radiusInKm="1"
      />
    </div>
    <div class="flex-col">
      <Map
        longitude="3.349921"
        latitude="45.533937"
        description="la Maison du tilleul (63490)"
        radiusInKm="2"
      />
    </div>
  </div>
</div>

<Modal id="modal_parking_mairie">
  <img
    loading="lazy"
    class="modal_img"
    src="/assets/parking_mairie.png"
    alt="Parkings Mairie"
  />
</Modal>
<Modal id="modal_parking_eglise">
  <img
    loading="lazy"
    class="modal_img"
    src="/assets/parking_eglise.png"
    alt="Parkings Eglise"
  />
</Modal>

<style>
  #info-container {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  }

  .modal_img {
    max-width: 82vw;
  }

  .right-corner {
    position: fixed;
    right: 0;
    bottom: 5%;
    cursor: pointer;
    text-align: end;
    width: 8.4rem;
    height: 65px;
    z-index: 100;
    -webkit-box-shadow: -3px 5px 20px 0px rgb(86 86 86 / 30%);
    box-shadow: -3px 5px 20px 0px rgb(86 86 86 / 30%);
    background-color: #fffff8;
    /* border: solid 2px #1e2b58; */
  }

  .right-corner > span {
    font-size: 1rem;
    position: absolute;
    right: 50px;
    font-weight: bolder;
  }

  #tousAntiCovid {
    height: 95%;
    position: absolute;
    right: 1px;
    top: 2px;
  }
</style>
