<script>
  import './styles.scss';
  import { Router, Route, Link} from 'svelte-routing';
  import  Home  from './routes/Home.svelte';
  import  Info  from './routes/Info.svelte';
  import  Photos  from './routes/Photos.svelte';
  import  Footer  from './components/Footer.svelte';

  export let url = '';

  // add shadow to sticky nav when scrolling
  window.addEventListener('scroll', function(e) {
    let y = window.scrollY;

    if (y === 0)
      document.querySelector("nav").classList.remove("shadow");
    else
     document.querySelector("nav").classList.add("shadow");   

});

</script>

<Router {url}>
  <main>
    <nav>
      <Link to="/">Accueil</Link>
      <Link to="photos">Photos</Link>
      <Link to="info">Informations</Link>
    </nav>
    <div>
      <Route path="**" component={Home} />
      <Route path="photos" component="{Photos}" />
      <Route path="info" component="{Info}" />
    </div>
  </main>
</Router>

<Footer/>