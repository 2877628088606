<script>
  import { onMount } from 'svelte';

  export let referenceDate;

  const MINUTES = 60;
  const HOURS = 60 * MINUTES;
  const DAYS = 24 * HOURS;
  const WEEKS = 7 * DAYS;

  let remaining = {
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  let calc = () => {
    let remainingSeconds = (referenceDate - new Date()) / 1000;


      remaining = {
        weeks: Math.floor(remainingSeconds / WEEKS),
        days: Math.floor((remainingSeconds % WEEKS) / DAYS),
        hours: Math.floor((remainingSeconds % DAYS) / HOURS),
        minutes: Math.floor((remainingSeconds % HOURS) / MINUTES),
        seconds: Math.floor(remainingSeconds % MINUTES),
      };
      window.setTimeout(() => {
        window.requestAnimationFrame(calc);
      }, 1000);

  };

  onMount(() => calc());
</script>

<div id="countdown-list">

    <div>
      <div>Semaines</div>
      <div>{remaining.weeks}</div>
    </div>

    <div>
      <div>Jours</div>
      <div>{remaining.days}</div>
    </div>

    <div>
      <div>Heures</div>
      <div>{remaining.hours}</div>
    </div>

    <div>
      <div>Minutes</div>
      <div>{remaining.minutes}</div>
    </div>

    <div>
      <div>Secondes</div>
      <div>{remaining.seconds}</div>
    </div>

</div>

<style>
  #countdown-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }

  #countdown-list>div {
    display: flex;
        flex-direction: column-reverse;
        align-items: center;
  }

  #countdown-list>div div:nth-child(2){
        font-size: xx-large;
        font-weight: 900;
     }
</style>
