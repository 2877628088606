<script>
  let year = new Date().getFullYear();
</script>

<footer>
  <span>
    Fait avec amour par Angel Thébault (© {year})
  </span>
</footer>

<style>
  footer {
    margin-top: 4rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
  }
</style>
