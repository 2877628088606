<script>
  import Divider from '../components/Divider.svelte';
  import Countdown from '../components/Countdown.svelte';
  import FullScreenHeroVideo from '../components/FullscreenHeroVideo.svelte';

  let DateCeremonieReligieuse = new Date('2021-09-18T15:30:00');

  function FormatDateInWords(date) {
    return date.toLocaleString('default', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }
</script>

<FullScreenHeroVideo>
  <p>Janice et Pierre-Antoine</p>

  <img
    id="silhouette"
    src="/assets/logo-japa.svg"
    alt="Silhouette de la famille"
  />

  <p>vous remercient chaleureusement d'avoir assisté à leur mariage</p>
</FullScreenHeroVideo>

<div class="center-middle-container">


  <div class="countdown-container">
    <Divider />
    <div>
      <h2 class="scaletext">{FormatDateInWords(DateCeremonieReligieuse)}</h2>
    </div>
    <div>
      <Countdown referenceDate={DateCeremonieReligieuse} />
    </div>
    <Divider />
  </div>


  <h5>
    Une vidéo préparée par la famille et les amis de Janice et Pierre-Antoine
    pour leur mariage. Un grand merci à Aurélien Louvet pour le montage.
  </h5>

  <div class="vimeo-container">
    <div style="padding:56.25% 0 0 0;position:relative;">
      <iframe
        src="https://player.vimeo.com/video/620601917?h=39187e24cb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        style="position:absolute;top:0;left:0;width:100%;height:100%;"
        title="Janice et Pierre-Antoine, Marry You"
      />
    </div>
    <script src="https://player.vimeo.com/api/player.js"></script>
  </div>

</div>

<style>

  .vimeo-container {
    width: 90vw;
  }

  #silhouette {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 20vw;
  }

  .countdown-container {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
  }
</style>
